import React from 'react';
import { useParams,Link } from 'react-router-dom';



const hotels = [
    {
        name: "Ibis Styles Hotel Westlands Nairobi",
        id: "hotel1",
        image: "https://dq5r178u4t83b.cloudfront.net/wp-content/uploads/sites/119/2019/10/22153838/gallery_hotel_01.jpg",
        description: "Ideal for business and private trips, this hotel boasts warm, excellent rooms and a convenient atmosphere for meetings. Enjoy stunning city views from the rooftop bar, well-appointed facilities, and friendly staff.",
        price: "KES 6,000 - 10,000",
        amenities: ["Rooftop Bar", "Meeting Facilities", "Dining Options"],
        location: "Westlands, Nairobi"
    },
    {
        name: "PrideInn Flamingo Beach Resort & Spa",
        id: "hotel2",
        image: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/426471015.jpg?k=5d195fb645eb11519454c614c64b33af4bd8c7501a7708d0a3bfb92fa59ac47f&o=&hp=1",
        description: "All-inclusive hotel with exceptional services, entertainment, and diverse dining options. Features talented animation team, beautiful spacious pool, excellent guest relations, and breathtaking Pavilion bar.",
        price: "KES 12,000 - 20,000",
        amenities: ["Pool", "Beach Access", "Spa"],
        location: "Shanzu, Mombasa"
    },
    {
        name: "Voyager Beach Resort",
        id: "hotel3",
        image: "https://i0.wp.com/www.safari254.com/wp-content/uploads/2017/01/Voyager-Beach-Resort_Activity-pool.jpg?resize=1000%2C560&",
        description: "Vibrant hotel featuring an amazing animation team, exceptional Italian restaurant, unique 'captain's table' experience, and variety of beautiful pools. Enjoy the popular Lookout Bar with stunning ocean views and refreshing cocktails.",
        price: "KES 10,000 - 18,000",
        amenities: ["Animation Team", "Italian Restaurant", "Multiple Pools"],
        location: "Nyali, Mombasa"
    },
    {
        name: "Radisson Blu Hotel & Residence, Nairobi Arboretum",
        id: "hotel4",
        image: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/2b/5f/89/42/hotel-exterior-night.jpg?w=700&h=-1&s=1",
        description: "Elegant hotel boasting serene environment and top-notch amenities. Friendly, professional staff provide superb service. Delicious food and high-quality rooms. Live music enhances festive ambiance.",
        price: "KES 15,000 - 25,000",
        amenities: ["Live Music", "High-Quality Rooms", "Serene Environment"],
        location: "Arboretum, Nairobi"
    },
    {
        name: "Crowne Plaza Nairobi Airport",
        id: "hotel5",
        image: "https://yellowzebrasafaris.com/media/33056/lazizi-premiere-121.jpg?width=2048&height=1024&format=jpg&v=1da5e1638910310",
        description: "Conveniently located near JKIA, this hotel offers excellent service, comfortable rooms, and a top-notch dining experience. Ideal for travelers and business guests.",
        price: "KES 18,000 - 30,000",
        amenities: ["Airport Shuttle", "Top-Notch Dining", "Comfortable Rooms"],
        location: "Nairobi"
    },
        {
            name: "Fairmont The Norfolk",
            id: 1,
            image: "https://www.fairmont.com/norfolk-hotel-nairobi",
            description: "A luxury hotel with top-tier service and elegant rooms.",
            price: "KES 20,000",
            amenities: ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant"],
            location: "Nairobi County, Nairobi"
        },
        {
            name: "The Boma Nairobi",
            id: 2,
            image: "https://www.theboma.co.ke",
            description: "Modern hotel with friendly staff and excellent customer care.",
            price: "KES 12,000",
            amenities: ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant"],
            location: "Nairobi County, Nairobi"
        },
        {
            name: "Tribe Hotel",
            id: 3,
            image: "https://www.tribe-hotel.com",
            description: "Boutique hotel with stylish decor and comprehensive amenities.",
            price: "KES 22,000",
            amenities: ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant"],
            location: "Nairobi County, Nairobi"
        },
        {
            name: "JW Marriott Hotel Nairobi",
            id: 4,
            image: "https://www.booking.com/hotel/ke/jw-marriott-nairobi.html",
            description: "Luxury hotel with modern amenities including a fitness center and outdoor swimming pool.",
            price: "KES 28,000",
            amenities: ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Bar"],
            location: "Nairobi County, Nairobi"
        },
        {
            name: "Hyatt Regency Nairobi Westlands",
            id: 5,
            image: "https://www.tripadvisor.com/Hotel_Review-g294207-d18906593-Reviews-Hyatt_Regency_Nairobi_Westlands-Nairobi.html",
            description: "A premium hotel featuring an outdoor pool, spa, and multiple dining options.",
            price: "KES 27,000",
            amenities: ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Bar"],
            location: "Nairobi County, Nairobi"
        },
        {
            name: "Mövenpick Hotel & Residences Nairobi",
            id: 6,
            image: "https://www.agoda.com/movenpick-hotel-residences-nairobi/hotel/nairobi-ke.html",
            description: "Offers spacious rooms, a rooftop pool, and a wide range of facilities.",
            price: "KES 20,000",
            amenities: ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Bar"],
            location: "Nairobi County, Nairobi"
        },
        {
            name: "The Panari Hotel",
            id: 7,
            image: "https://www.agoda.com/the-panari-hotel/hotel/nairobi-ke.html",
            description: "Located along Mombasa Road, with amenities including a spa and a skating rink.",
            price: "KES 12,000",
            amenities: ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Skating Rink"],
            location: "Nairobi County, Nairobi"
        },
        {
            name: "Weston Hotel",
            id: 8,
            image: "https://www.booking.com/hotel/ke/weston.html",
            description: "Situated in Lang’ata, it features an outdoor pool, free Wi-Fi, and a restaurant.",
            price: "KES 13,000",
            amenities: ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Bar"],
            location: "Nairobi County, Nairobi"
        },
        {
            name: "Sarova Whitesands Beach Resort & Spa",
            id: 9,
            image: "https://www.sarovahotels.com/whitesands-mombasa",
            description: "Beachfront resort with spacious rooms and excellent service.",
            price: "KES 15,000",
            amenities: ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Beach Access"],
            location: "Mombasa County, Mombasa"
        },
        {
            name: "Voyager Beach Resort",
            id: 10,
            image: "https://www.heritage-eastafrica.com/voyager",
            description: "Vibrant resort with themed restaurants and various pools.",
            price: "KES 14,000",
            amenities: ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Beach Access"],
            location: "Mombasa County, Mombasa"
        },
        {
            name: "Bamburi Beach Hotel",
            id: 11,
            image: "https://www.bamburibeachkenya.com",
            description: "Beachfront hotel with ocean views and family-friendly activities.",
            price: "KES 10,000",
            amenities: ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Beach Access"],
            location: "Mombasa County, Mombasa"
        },
        {
            name: "PrideInn Paradise Beach Resort and Spa",
            id: 12,
            image: "https://www.prideinn.co.ke",
            description: "Features water slides, a spa, and direct beach access.",
            price: "KES 16,000",
            amenities: ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Beach Access"],
            location: "Mombasa County, Mombasa"
        },
        {
            name: "Severin Sea Lodge",
            id: 13,
            image: "https://www.severin-travel.com",
            description: "Known for its tranquil gardens and beachfront location.",
            price: "KES 12,000",
            amenities: ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Beach Access"],
            location: "Mombasa County, Mombasa"
        },
        {
            name: "Serena Beach Resort and Spa",
            id: 14,
            image: "https://www.serenahotels.com/serenabeachhotel",
            description: "Offers luxurious accommodations and a variety of water sports.",
            price: "KES 18,000",
            amenities: ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Beach Access"],
            location: "Mombasa County, Mombasa"
        },
        {
            name: "Sarova Woodlands Hotel and Spa",
            id: 15,
            image: "https://www.sarovahotels.com/woodlands-nakuru",
            description: "Located in a serene environment with modern facilities.",
            price: "KES 10,000",
            amenities: ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant"],
            location: "Nakuru County, Nakuru"
        },
        {
            name: "Merica Hotel",
            id: 16,
            image: "https://www.mericahotel.com",
            description: "Offers a comfortable stay with amenities like an outdoor pool and fitness center.",
            price: "KES 8,000",
            amenities: ["Free WiFi", "Swimming Pool", "Fitness Center", "Restaurant"],
            location: "Nakuru County, Nakuru"
        }
    
    
];

const HotelDetail = () => {
    const { id } = useParams();
    const hotel = hotels.find(hotel => hotel.id === id);

    if (!hotel) {
        return <div>Hotel not found</div>;
    }

    return (
        <div className=" flex flex-col sm:flex-row items-center justify-between py-8 px-4 mt-32">
            <div className='w-full sm:w-1/2 relative'>
            <img src={hotel.image} alt={hotel.name} className="object-cover w-full h-full rounded-lg mb-4 sm:mb-0 mr-0 sm:mr-2" />
            <div className="flex items-center justify-center">
                <button className="bg-red-500 items-center md:p-3 p-2 absolute sm:bottom-28 bottom-20 rounded-xl text-sm md:text-base text-white md:w-48  font-bold hover:bg-white hover:text-orange-700 hover: transition-all duration-250">
                    <Link to="/contact">Book Now</Link>
                </button>
            </div>

            </div>
            <div className='sm:w-1/2 ml-2'>
            <h1 className="text-2xl font-bold text-yellow-600 mb-2">{hotel.name}</h1>
            <p className="text-gray-700 mb-2">{hotel.location}</p>
            <p className="text-green-600 font-semibold mb-4">{hotel.price}</p>
            <p className="text-gray-800">{hotel.description}</p>
            <div className="list-disc flex  list-inside mt-4">
                {hotel.amenities.map((amenity, index) => (
                    <p key={index} className="bg-gray-300 ml-3 p-2 text-black rounded-md">{amenity}</p>
                ))}
            </div>
            </div>
        </div>
    );
};

export default HotelDetail;
