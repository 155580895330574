
import logo from './Images/logo-no-background.png';
import { Link } from 'react-router-dom';

const NavBar = () => {
    return (
        
        <div className=" bg-white opacity-95 fixed top-0 left-0 w-full shadow-lg z-50 py-4 mb-5">
            <nav className="flex items-center px-3 sm:px-6 lg:px-8">
                <div className="flex items-center">
                    <label htmlFor="my-drawer" className="lg:hidden drawer-button">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="23" height="23" viewBox="0 0 26 26">
                            <path d="M 0 4 L 0 6 L 26 6 L 26 4 Z M 0 12 L 0 14 L 26 14 L 26 12 Z M 0 20 L 0 22 L 26 22 L 26 20 Z"></path>
                        </svg>
                    </label>
                    <img className="ml-2 sm:w-[120px] w-[90px] mr-7" src={logo} alt="Logo" />
                </div>

                <div className="hidden lg:flex items-center just space-x-4">
                        <Link to="/" className="text-black transition-all ease-in duration-100 hover:border-b-2 hover:border-yellow-600">Home</Link>
                        <Link to="/hotels" className="text-black transition-all ease-in duration-100 hover:border-b-2 hover:border-yellow-600">Hotels</Link>
                        <Link to="/contact" className="text-black transition-all ease-in-out duration-100 border-2 border-green-500 rounded-2xl p-2 hover:text-yellow-600">Contact us</Link>
                </div>
                
                <div className="lg:hidden drawer">
                    <input id="my-drawer" type="checkbox" className="drawer-toggle" />
                    <div className="drawer-content">
                        {/* Page content here */}
                    </div>
                    <div className="drawer-side">
                        <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
                        <ul className="menu p-4 w-80 min-h-full bg-base-200 text-base-content">
                            {/* Sidebar content here */}
                            
                        <li><Link to="/" className="">Home</Link></li>
                        <li><Link to="/hotels" className="">Hotels</Link></li>
                        <li><Link to="/contact" className="">Contact us</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default NavBar;
