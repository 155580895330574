import {Link} from 'react-router-dom';
import hotelpic from './Images/hotel.jpg'

const Landing = () => {
    return ( 
        <div className=''>
                   <div className="relative overflow-hidden rounded-md m-2 lg:h-screen">
            <img 
                src={hotelpic} 
                alt="Hotel Pic" 
                className="size-fit transform transition-transform duration-500 hover:scale-110 rounded-t-md" 
            />
            <div className="absolute  top-1/2 font-bold text-xl md:text-4xl text-start w-full rounded-md text-white">
                "Embark on Dreams, Explore the World: Your Journey, Our Comfort!"
            </div>
            <div className="flex items-center justify-center">
                <button className="bg-red-500 items-center md:p-3 p-2 absolute sm:bottom-28 bottom-5 rounded-xl text-sm md:text-base text-white md:w-48  font-bold hover:bg-white hover:text-orange-700 hover: transition-all duration-250">
                    <Link to="/hotels">View Hotels</Link>
                </button>
            </div>
        </div>
            
        </div>
     );
}
 
export default Landing;