import React from 'react';
import { Link  } from 'react-router-dom';

const hotels = [
    {
        name: "Ibis Styles Hotel Westlands Nairobi",
        id: "hotel1",
        image: "https://dq5r178u4t83b.cloudfront.net/wp-content/uploads/sites/119/2019/10/22153838/gallery_hotel_01.jpg",
        description: "Ideal for business and private trips, this hotel boasts warm, excellent rooms and a convenient atmosphere for meetings. Enjoy stunning city views from the rooftop bar, well-appointed facilities, and friendly staff.",
        price: "KES 6,000 - 10,000",
        amenities: ["Rooftop Bar", "Meeting Facilities", "Dining Options"],
        location: "Westlands, Nairobi"
    },
    {
        name: "PrideInn Flamingo Beach Resort & Spa",
        id: "hotel2",
        image: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/426471015.jpg?k=5d195fb645eb11519454c614c64b33af4bd8c7501a7708d0a3bfb92fa59ac47f&o=&hp=1",
        description: "All-inclusive hotel with exceptional services, entertainment, and diverse dining options. Features talented animation team, beautiful spacious pool, excellent guest relations, and breathtaking Pavilion bar.",
        price: "KES 12,000 - 20,000",
        amenities: ["Pool", "Beach Access", "Spa"],
        location: "Shanzu, Mombasa"
    },
    {
        name: "Voyager Beach Resort",
        id: "hotel3",
        image: "https://i0.wp.com/www.safari254.com/wp-content/uploads/2017/01/Voyager-Beach-Resort_Activity-pool.jpg?resize=1000%2C560&",
        description: "Vibrant hotel featuring an amazing animation team, exceptional Italian restaurant, unique 'captain's table' experience, and variety of beautiful pools. Enjoy the popular Lookout Bar with stunning ocean views and refreshing cocktails.",
        price: "KES 10,000 - 18,000",
        amenities: ["Animation Team", "Italian Restaurant", "Multiple Pools"],
        location: "Nyali, Mombasa"
    },
    {
        name: "Radisson Blu Hotel & Residence, Nairobi Arboretum",
        id: "hotel4",
        image: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/2b/5f/89/42/hotel-exterior-night.jpg?w=700&h=-1&s=1",
        description: "Elegant hotel boasting serene environment and top-notch amenities. Friendly, professional staff provide superb service. Delicious food and high-quality rooms. Live music enhances festive ambiance.",
        price: "KES 15,000 - 25,000",
        amenities: ["Live Music", "High-Quality Rooms", "Serene Environment"],
        location: "Arboretum, Nairobi"
    },
    {
        name: "Crowne Plaza Nairobi Airport",
        id: "hotel5",
        image: "https://yellowzebrasafaris.com/media/33056/lazizi-premiere-121.jpg?width=2048&height=1024&format=jpg&v=1da5e1638910310",
        description: "Conveniently located near JKIA, this hotel offers excellent service, comfortable rooms, and a top-notch dining experience. Ideal for travelers and business guests.",
        price: "KES 18,000 - 30,000",
        amenities: ["Airport Shuttle", "Top-Notch Dining", "Comfortable Rooms"],
        location: "Nairobi"
    }
];

const PopularHotels = () => {
    return (
        <div className="py-8 px-4">
            <h1 className='text-xl sm:text-4xl font-bold mb-4 text-green-600'>Check out the most Popular Hotels</h1>
            <div className="overflow-x-scroll flex space-x-6">
                {hotels.map(hotel => (
                    <div key={hotel.id} className="w-80 flex-shrink-0 bg-white rounded-lg shadow-lg hover:border-2 hover:border-green-600 transition-all duration-200">
                        <img src={hotel.image} alt={hotel.name} className="w-full h-48 object-cover rounded-t-lg" />
                        <div className="p-4">
                            <h3 className="text-xl font-bold">{hotel.name}</h3>
                            <p className="text-gray-700">{hotel.location}</p>
                            <p className="text-green-600 font-semibold">{hotel.price}</p>
                            <Link to={`/hotels/${hotel.id}`} className='btn-link text-orange-700 hover:text-yellow-800 active:text-yellow-500 transition-all duration-250'>View more</Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PopularHotels;