import { Link } from 'react-router-dom';
import { useState } from 'react';

const Hotels = () => {
  const [hotels] = useState([
    {
      "id": 1,
      "name": "Fairmont The Norfolk",
      "price_per_night_kes": 20000,
      "image": "https://www.micato.com/wp-content/uploads/2018/09/fairmont-the-norfolk-16.jpg",
      "description": "A luxury hotel with top-tier service and elegant rooms.",
      "location": {
        "county": "Nairobi County",
        "city": "Nairobi"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant"]
    },
    {
      "id": 2,
      "name": "The Boma Nairobi",
      "price_per_night_kes": 12000,
      "image": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/319103911.jpg?k=dbe89dca14add08b69577a7df8180efa2a09e981e1aa357b67683d530d925a1d&o=&hp=1",
      "description": "Modern hotel with friendly staff and excellent customer care.",
      "location": {
        "county": "Nairobi County",
        "city": "Nairobi"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant"]
    },
    {
      "id": 3,
      "name": "Tribe Hotel",
      "price_per_night_kes": 22000,
      "image": "https://media.cntraveler.com/photos/53dac4b66dec627b14a01753/master/pass/tribe-hotel-nairobi-kenya-111959-4.jpg",
      "description": "Boutique hotel with stylish decor and comprehensive amenities.",
      "location": {
        "county": "Nairobi County",
        "city": "Nairobi"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant"]
    },
    {
      "id": 4,
      "name": "JW Marriott Hotel Nairobi",
      "price_per_night_kes": 28000,
      "image": "https://cache.marriott.com/content/dam/marriott-renditions/TPAJD/tpajd-outdoor-1350-hor-feat.jpg?output-quality=70&interpolation=progressive-bilinear&downsize=1920px:*",
      "description": "Luxury hotel with modern amenities including a fitness center and outdoor swimming pool.",
      "location": {
        "county": "Nairobi County",
        "city": "Nairobi"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Bar"]
    },
    {
      "id": 5,
      "name": "Hyatt Regency Nairobi Westlands",
      "price_per_night_kes": 27000,
      "image": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/545920104.jpg?k=ee09dc608c25b2f28cccacb0b10dd37a3953e1102101e3c25a06a4db8a2546b7&o=&hp=1",
      "description": "A premium hotel featuring an outdoor pool, spa, and multiple dining options.",
      "location": {
        "county": "Nairobi County",
        "city": "Nairobi"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Bar"]
    },
    {
      "id": 6,
      "name": "Mövenpick Hotel & Residences Nairobi",
      "price_per_night_kes": 20000,
      "image": "https://gos3.ibcdn.com/4948f88caced11e8b4d40a9df65c8753.jpg",
      "description": "Offers spacious rooms, a rooftop pool, and a wide range of facilities.",
      "location": {
        "county": "Nairobi County",
        "city": "Nairobi"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Bar"]
    },
    {
      "id": 7,
      "name": "The Panari Hotel",
      "price_per_night_kes": 12000,
      "image": "https://content.r9cdn.net/rimg/himg/57/66/9e/expediav2-244466-3334476871-211448.jpg?width=1200&height=630&crop=true",
      "description": "Located along Mombasa Road, with amenities including a spa and a skating rink.",
      "location": {
        "county": "Nairobi County",
        "city": "Nairobi"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Skating Rink"]
    },
    {
      "id": 8,
      "name": "Weston Hotel",
      "price_per_night_kes": 13000,
      "image": "https://weston.hotelsmixnairobi.com/data/Photos/OriginalPhoto/1218/121803/121803550/nairobi-weston-hotel-photo-100.JPEG",
      "description": "Situated in Lang’ata, it features an outdoor pool, free Wi-Fi, and a restaurant.",
      "location": {
        "county": "Nairobi County",
        "city": "Nairobi"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Bar"]
    },
    {
      "id": 9,
      "name": "Sarova Whitesands Beach Resort & Spa",
      "price_per_night_kes": 15000,
      "image": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/224831931.jpg?k=479145ed944b5b31a5daa319db99703e0ce74e0adfb40a5dcbc72fa6b9939388&o=&hp=1",
      "description": "Beachfront resort with spacious rooms and excellent service.",
      "location": {
        "county": "Mombasa County",
        "city": "Mombasa"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Beach Access"]
    },
    {
      "id": 10,
      "name": "Voyager Beach Resort",
      "price_per_night_kes": 14000,
      "image": "https://i0.wp.com/www.safari254.com/wp-content/uploads/2017/01/Voyager-Beach-Resort_Activity-pool.jpg?resize=1000%2C667r",
      "description": "Vibrant resort with themed restaurants and various pools.",
      "location": {
        "county": "Mombasa County",
        "city": "Mombasa"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Beach Access"]
    },
    {
      "id": 11,
      "name": "Bamburi Beach Hotel",
      "price_per_night_kes": 10000,
      "image": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/363461147.jpg?k=eb40dc02f0e1ee8830c70b9172d72e20f8a9a3f4ffcb0790983317bf99a8b1dc&o=&hp=1",
      "description": "Beachfront hotel with ocean views and family-friendly activities.",
      "location": {
        "county": "Mombasa County",
        "city": "Mombasa"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Beach Access"]
    },
    {
      "id": 12,
      "name": "PrideInn Paradise Beach Resort and Spa",
      "price_per_night_kes": 16000,
      "image": "https://theholidaydealers.com/wp-content/uploads/2023/03/Prideinn-Paradise-Mombasa-Shanzu-Beach-30.jpg",
      "description": "Features water slides, a spa, and direct beach access.",
      "location": {
        "county": "Mombasa County",
        "city": "Mombasa"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Beach Access"]
    },
    {
      "id": 13,
      "name": "Severin Sea Lodge",
      "price_per_night_kes": 12000,
      "image": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/68288692.jpg?k=02573852c963bbb16a7aa5aafd33671dfe4bcd8b22baba85b218ac87b23b399c&o=&hp=1",
      "description": "Known for its tranquil gardens and beachfront location.",
      "location": {
        "county": "Mombasa County",
        "city": "Mombasa"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Beach Access"]
    },
    {
      "id": 14,
      "name": "Serena Beach Resort and Spa",
      "price_per_night_kes": 18000,
      "image": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/64918103.jpg?k=4554c9fa940b5e0ffece5a933d8c3b82c9274810238f2cad74c2fade1a2ed082&o=&hp=1",
      "description": "Offers luxurious accommodations and a variety of water sports.",
      "location": {
        "county": "Mombasa County",
        "city": "Mombasa"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Beach Access"]
    },
    {
      "id": 15,
      "name": "Sarova Woodlands Hotel and Spa",
      "price_per_night_kes": 10000,
      "image": "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/44/e6/b5/sarova-woodlands.jpg?w=1200&h=-1&s=1",
      "description": "Located in a serene environment with modern facilities.",
      "location": {
        "county": "Nakuru County",
        "city": "Nakuru"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant"]
    },
    {
      "id": 16,
      "name": "Merica Hotel",
      "price_per_night_kes": 8000,
      "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF3X_0UrepWwLxyiKCMZiykIAB_AFEONwLSQ&s",
      "description": "Offers a comfortable stay with amenities like an outdoor pool and fitness center.",
      "location": {
        "county": "Nakuru County",
        "city": "Nakuru"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Fitness Center", "Restaurant"]
    },
    {
      "id": 17,
      "name": "Lake Naivasha Country Club",
      "price_per_night_kes": 18000,
      "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMg2eIt68qoxvgB92g90aCmdbRD9G4jwkNTw&s",
      "description": "Set on the shores of Lake Naivasha, offering stunning views and a range of outdoor activities.",
      "location": {
        "county": "Nakuru County",
        "city": "Naivasha"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Golf Course"]
    },
    {
      "id": 18,
      "name": "Great Rift Valley Lodge & Golf Resort",
      "price_per_night_kes": 25000,
      "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTue4qaYlYsuJXehM6UQ4ylYLtPLhyzeSNeHQ&s",
      "description": "Located on the edge of the Great Rift Valley, offering panoramic views and a championship golf course.",
      "location": {
        "county": "Nakuru County",
        "city": "Naivasha"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Golf Course"]
    },
    {
      "id": 19,
      "name": "Enashipai Resort & Spa",
      "price_per_night_kes": 28000,
      "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTISyN1_goHYGro2p3azmC04QdfrLD9eyzC2g&s",
      "description": "A luxury resort offering world-class spa facilities and beautiful gardens.",
      "location": {
        "county": "Nakuru County",
        "city": "Naivasha"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant"]
    },
    {
      "id": 20,
      "name": "Naivasha Simba Lodge",
      "price_per_night_kes": 15000,
      "image": "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/09/c2/37/d9/lake-naivasha-simba-lodge.jpg?w=700&h=-1&s=1",
      "description": "Set in a wildlife sanctuary, offering rustic charm and proximity to nature.",
      "location": {
        "county": "Nakuru County",
        "city": "Naivasha"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Restaurant", "Game Drives"]
    },
    {
      "id": 21,
      "name": "Eagle Palace Hotel",
      "price_per_night_kes": 12000,
      "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROmQjTycRgyE6erYtzs_KQ6lxB3n5r4AGZZw&s",
      "description": "A modern hotel with spacious rooms and conference facilities.",
      "location": {
        "county": "Nakuru County",
        "city": "Nakuru"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Conference Facilities"]
    },
    {
      "id": 22,
      "name": "Sovereign Hotel",
      "price_per_night_kes": 20000,
      "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBiJlJoJis2miU3TVk67cd35DY0QxwFVT5RA&s",
      "description": "Luxury accommodation with a rooftop bar and panoramic views of Nakuru.",
      "location": {
        "county": "Kisumu County",
        "city": "Kisumu"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Bar"]
    },
    {
      "id": 24,
      "name": "Kiangazi House",
      "price_per_night_kes": 30000,
      "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnlnczKCVLUWPDG1hegSglxwURhZE_lYdl_A&s",
      "description": "A luxurious retreat nestled in the lush landscapes of Nakuru.",
      "location": {
        "county": "Nakuru County",
        "city": "Nakuru"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant"]
    },
    {
      "id": 25,
      "name": "Sopa Lodge Nakuru",
      "price_per_night_kes": 22000,
      "image": "https://citybreaks.africa/wp-content/uploads/2022/06/Lake-Nakuru-Sopa-Lodge-16-850x540.jpg",
      "description": "Offers panoramic views of Lake Nakuru and luxurious accommodations.",
      "location": {
        "county": "Nakuru County",
        "city": "Nakuru"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant"]
    },
    {
      "id": 26,
      "name": "Naivasha Kongoni Lodge",
      "price_per_night_kes": 16000,
      "image": "https://naivashahotels.com/kenya/wp-content/uploads/2022/06/Naivasha-Kongoni-Lodge-10.jpg",
      "description": "Set in a private conservancy, offering intimate wildlife encounters and breathtaking views.",
      "location": {
        "county": "Nakuru County",
        "city": "Naivasha"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Restaurant", "Game Drives"]
    },
    {
      "id": 27,
      "name": "Acacia Premier Hotel",
      "price_per_night_kes": 25000,
      "image": "https://www.kayak.com/rimg/himg/03/db/51/expediav2-2630882-cdd6e2-758386.jpg?width=1366&height=768&crop=true",
      "description": "Luxury hotel with stunning views of Lake Victoria and upscale amenities.",
      "location": {
        "county": "Kisumu County",
        "city": "Kisumu"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Bar"]
    },
    {
      "id": 28,
      "name": "Imperial Hotel Kisumu",
      "price_per_night_kes": 18000,
      "image": "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0a/34/eb/ba/evening-profile-iexpress.jpg?w=700&h=-1&s=1",
      "description": "Located in the heart of Kisumu, offering comfortable accommodations and conference facilities.",
      "location": {
        "county": "Kisumu County",
        "city": "Kisumu"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Fitness Center", "Restaurant", "Conference Facilities"]
    },
    {
      "id": 29,
      "name": "The Vic Hotel",
      "price_per_night_kes": 12000,
      "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTR4krYHU2kAhse1MZkr7XCoMWXttBsQqeSIQ&s",
      "description": "A modern hotel with spacious rooms and a rooftop restaurant offering panoramic views of Kisumu.",
      "location": {
        "county": "Kisumu County",
        "city": "Kisumu"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Fitness Center", "Restaurant"]
    },
    {
      "id": 30,
      "name": "Pinecone Hotel",
      "price_per_night_kes": 10000,
      "image": "https://images.trvl-media.com/lodging/18000000/17160000/17151200/17151103/d488b102.jpg?impolicy=resizecrop&rw=575&rh=575&ra=fill",
      "description": "Affordable hotel with comfortable rooms and a cozy ambiance.",
      "location": {
        "county": "Kisumu County",
        "city": "Kisumu"
      },
      "amenities": ["Free WiFi", "Restaurant"]
    },
    {
      "id": 31,
      "name": "Sirikwa Hotel",
      "price_per_night_kes": 8000,
      "image": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/250451031.jpg?k=602afdeb36b3a6373617b236d9046037488afafb53ba4ec06319e6cbc6b58985&o=&hp=1",
      "description": "A budget-friendly hotel offering basic amenities and friendly service.",
      "location": {
        "county": "Eldoret County",
        "city": "Eldoret"
      },
      "amenities": ["Free WiFi", "Restaurant"]
    },
    {
      "id": 32,
      "name": "Boma Inn Eldoret",
      "price_per_night_kes": 15000,
      "image": "https://res.cloudinary.com/simplotel/image/upload/x_0,y_0,w_4032,h_3024,r_0,c_crop,q_80,fl_progressive/w_600,f_auto,c_fit/boma-inn-eldoret/Sunset_thg9st",
      "description": "Located near Eldoret International Airport, offering convenient accommodations for travelers.",
      "location": {
        "county": "Eldoret County",
        "city": "Eldoret"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Fitness Center", "Restaurant", "Bar"]
    },
    {
      "id": 35,
      "name": "Baobab Sea Lodge",
      "price_per_night_kes": 20000,
      "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSX8q2HfeHcNbdrI69F9NFxYU3YTVskkOgDAg&s",
      "description": "Located on the beautiful coast of Kilifi, offering beachfront accommodations and water sports.",
      "location": {
        "county": "Kilifi County",
        "city": "Kilifi"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Beach Access"]
    },
    {
      "id": 36,
      "name": "Mnarani Club & Spa",
      "price_per_night_kes": 18000,
      "image": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/116421464.jpg?k=99cde96e9b0300336035d57de65e7d4a8c217e7c3080dfa698f7b9fc33284e65&o=&hp=1",
      "description": "A beachfront resort offering relaxation and adventure activities in Kilifi.",
      "location": {
        "county": "Kilifi County",
        "city": "Kilifi"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Beach Access"]
    },
    {
      "id": 37,
      "name": "Sunrise Resort",
      "price_per_night_kes": 15000,
      "image": "https://a0.muscache.com/im/pictures/miso/Hosting-781113187683146751/original/955d8e3e-7eeb-4a70-b1c2-f3b9721716e6.jpeg",
      "description": "Located on Bofa Beach, offering serene accommodations and stunning ocean views.",
      "location": {
        "county": "Kilifi County",
        "city": "Kilifi"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Restaurant", "Beach Access"]
    },
    {
      "id": 38,
      "name": "Vipingo Ridge",
      "price_per_night_kes": 30000,
      "image": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/205784338.jpg?k=e80f20be37e2ceaabc7624949ba286762e3652cc08eaacb9aebb90cffed64f57&o=&hp=1",
      "description": "A luxury golf resort offering panoramic views of the Indian Ocean and world-class amenities.",
      "location": {
        "county": "Kilifi County",
        "city": "Kilifi"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Spa", "Fitness Center", "Restaurant", "Golf Course"]
    },
    {
      "id": 39,
      "name": "Barracuda Inn Resort",
      "price_per_night_kes": 22000,
      "image": "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/08/73/7b/93/barracuda-inn.jpg?w=700&h=-1&s=1",
      "description": "Set amidst lush gardens, offering a tranquil retreat in Kilifi.",
      "location": {
        "county": "Kilifi County",
        "city": "Kilifi"
      },
      "amenities": ["Free WiFi", "Swimming Pool", "Restaurant", "Beach Access"]
    },        
  ]);

  const groupByLocation = hotels.reduce((groupedHotels, hotel) => {
    const { county } = hotel.location;
    if (!groupedHotels[county]) {
      groupedHotels[county] = [];
    }
    groupedHotels[county].push(hotel);
    return groupedHotels;
  }, {});

  return (
    <div className='mt-36'>
      {Object.keys(groupByLocation).map(county => (
        <div key={county}>
          <h1 className='text-xl sm:text-4xl font-bold mb-4 text-green-600'>Hotels in {county}</h1>
          <div className="py-8 px-4">
            <div className="overflow-x-scroll flex space-x-6">
              {groupByLocation[county].map(hotel => (
                <div key={hotel.id} className="w-80 flex-shrink-0 bg-white rounded-lg shadow-lg hover:border-2 hover:border-green-600 transition-all duration-200">
                  <img src={hotel.image} alt={hotel.name} className="w-80 h-48 object-cover rounded-t-lg" />
                  <div className="p-4">
                    <h3 className="text-lg sm:xl font-bold w-3/4">{hotel.name}</h3>
                    <p className="text-gray-700">{hotel.location.county}</p>
                    <p className="text-green-600 font-semibold">{hotel.price_per_night_kes} KES per night</p>
                    <Link to={`/hotel/${hotel.id}`} className='btn-link text-orange-700 hover:text-yellow-800 active:text-yellow-500 transition-all duration-250'>View more</Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Hotels;
