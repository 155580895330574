import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landing from './Landing.js';
import NavBar from './NavBar.js';
import PopularHotels from './PopularHotels.js';
import HotelDetail from './HotelDetail.js';
import About from './About.js';
import Hotels from './Hotels.js';
import HotelDesc from './HotelDesc.js';
import Contact from './Contact.js';
import Footer from './Footer.js';

function App() {
  return (
    <div className="font-serif flex flex-col ">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route exact path="/" element={
            <>
              <Landing />
              <PopularHotels />
            </>
          } />
          <Route path="/hotels/:id" element={<HotelDetail />} />
          <Route path="/about" element={<About />}/>
          <Route path="/hotels" element={<Hotels/>}/>
          <Route path="/hotel/:id" element={<HotelDesc />} />
          <Route path='/contact' element={<Contact />}/>


        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}
export default App;