import React from 'react';
import { FaFacebook, FaInstagram, FaTiktok, FaPhone, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="w-full bg-orange-600 text-white py-8 px-4 md:px-6">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="mb-4 md:mb-0">
          <h3 className="text-xl font-bold">Graspable Dreams</h3>
        </div>
        <div className="flex space-x-4">
          <a href="https://www.facebook.com/profile.php?id=61558653907389" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="text-white hover:text-gray-300" size={24} />
          </a>
          <a href="https://www.instagram.com/bookingsbygraspable/" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="text-white hover:text-gray-300" size={24} />
          </a>
          <a href="https://www.tiktok.com/@graspabledreams?lang=en" target="_blank" rel="noopener noreferrer">
            <FaTiktok className="text-white hover:text-gray-300" size={24} />
          </a>
        </div>
        <div className='mt-4 flex items-center space-x-2'>
            <FaPhone className="text-white hover:text-gray-300" size={20} />
            <p>+254790478752</p>
        </div>
        <div className='mt-4 flex items-center space-x-2'>
            <FaEnvelope className="text-white hover:text-gray-300" size={20} />
            <p>bookingsbygraspable@gmail.com </p>
        </div>

      </div>
      <div className="mt-8 text-center text-sm text-gray-300">
        <p>© 2024 Graspable Dreams. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
